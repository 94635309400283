html {
    scroll-behavior: smooth;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-link {
    color: #61dafb;
}
